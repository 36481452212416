@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
    font-display: swap;
  }
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .word-spacing {
    word-spacing: 99999px;
  }
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  background: #1e1b46;
  font-size: 10px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: 'Roboto', 'Montserrat', sans-serif;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0);
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 12px;
  color: white;
  opacity: 0.3;
}

.cc-slider-container {
  pointer-events: none !important;
}

img {
  pointer-events: none;
}
